import { ProviderLogo as UiKitProviderLogo } from '@workos-inc/ui-kit';
import { ProviderOther } from '@workos-inc/ui-kit/dist/components/provider-logo/provider-other';
import { ConnectionType as UiKitConnectionType } from '@workos-inc/ui-kit/dist/interfaces/connection-type';
import { DirectoryType as UiKitDirectoryType } from '@workos-inc/ui-kit/dist/interfaces/directory-type';
import { ConnectionType, DirectoryType } from 'graphql/generated';
import React from 'react';
import { unreachable } from 'utils/unreachable';

const toUiKitProvider = (
  provider: ConnectionType | DirectoryType,
):
  | {
      provider: UiKitConnectionType | UiKitDirectoryType;
    }
  | {
      provider: 'other';
      name: string;
    } => {
  switch (provider) {
    case ConnectionType.Adfssaml:
      return { provider: UiKitConnectionType.Adfssaml };
    case ConnectionType.AdpOidc:
      return { provider: UiKitConnectionType.AdpOidc };
    case ConnectionType.Auth0Saml:
      return { provider: UiKitConnectionType.Auth0Saml };
    case ConnectionType.AzureSaml:
      return { provider: UiKitConnectionType.AzureSaml };
    case ConnectionType.CyberArkSaml:
      return { provider: UiKitConnectionType.CyberArkSaml };
    case ConnectionType.DuoSaml:
      return { provider: UiKitConnectionType.DuoSaml };
    case ConnectionType.GenericOidc:
      return { provider: UiKitConnectionType.GenericOidc };
    case ConnectionType.GenericSaml:
      return { provider: UiKitConnectionType.GenericSaml };
    case ConnectionType.GoogleOAuth:
      return { provider: UiKitConnectionType.GoogleOAuth };
    case ConnectionType.GoogleSaml:
      return { provider: UiKitConnectionType.GoogleSaml };
    case ConnectionType.JumpCloudSaml:
      return { provider: UiKitConnectionType.JumpCloudSaml };
    case ConnectionType.KeycloakSaml:
      return { provider: 'other', name: 'Keycloak SAML' };
    case ConnectionType.MagicLink:
      return { provider: UiKitConnectionType.MagicLink };
    case ConnectionType.MicrosoftOAuth:
      return { provider: 'other', name: 'Microsoft OAuth' };
    case ConnectionType.OktaSaml:
      return { provider: UiKitConnectionType.OktaSaml };
    case ConnectionType.OneLoginSaml:
      return { provider: UiKitConnectionType.OneLoginSaml };
    case ConnectionType.PingFederateSaml:
      return { provider: UiKitConnectionType.PingFederateSaml };
    case ConnectionType.PingOneSaml:
      return { provider: UiKitConnectionType.PingOneSaml };
    case ConnectionType.RipplingSaml:
      return { provider: UiKitDirectoryType.Rippling };
    case ConnectionType.SalesforceSaml:
      return { provider: UiKitConnectionType.SalesforceSaml };
    case ConnectionType.ShibbolethSaml:
      return { provider: 'other', name: 'Shibboleth' };
    case ConnectionType.VMwareSaml:
      return { provider: UiKitConnectionType.VMwareSaml };
    case DirectoryType.AzureScimv2_0:
      return { provider: UiKitDirectoryType.AzureScimv2_0 };
    case DirectoryType.BambooHr:
      return { provider: UiKitDirectoryType.BambooHr };
    case DirectoryType.BreatheHr:
      return { provider: UiKitDirectoryType.BreatheHr };
    case DirectoryType.FourthHr:
      return { provider: UiKitDirectoryType.FourthHr };
    case DirectoryType.CyberArkScimV2_0:
      return { provider: UiKitDirectoryType.CyberArkScim2_0 };
    case DirectoryType.GenericScimv1_1:
      return { provider: UiKitDirectoryType.GenericScimv1_1 };
    case DirectoryType.GenericScimv2_0:
      return { provider: UiKitDirectoryType.GenericScimv2_0 };
    case DirectoryType.GSuiteDirectory:
      return { provider: UiKitDirectoryType.GSuiteDirectory };
    case DirectoryType.Gusto:
      return { provider: UiKitDirectoryType.Gusto };
    case DirectoryType.Hibob:
      return { provider: UiKitDirectoryType.Hibob };
    case DirectoryType.JumpCloudScimV2_0:
      return { provider: UiKitDirectoryType.JumpCloudScimV2_0 };
    case DirectoryType.OktaScimv1_1:
      return { provider: UiKitDirectoryType.OktaScimv1_1 };
    case DirectoryType.OktaScimv2_0:
      return { provider: UiKitDirectoryType.OktaScimv2_0 };
    case DirectoryType.OneLoginScimV2_0:
      return { provider: 'other', name: 'OneLogin SCIM' };
    case DirectoryType.Rippling:
      return { provider: UiKitDirectoryType.Rippling };
    case DirectoryType.S3:
      return { provider: 'other', name: 'S3' };
    case DirectoryType.Workday:
      return { provider: UiKitDirectoryType.Workday };
    default:
      return unreachable(provider);
  }
};

export interface ProviderLogoProps {
  provider: ConnectionType | DirectoryType;
  width?: string;
}

export const ProviderLogo: React.FC<Readonly<ProviderLogoProps>> = ({
  provider,
  ...props
}) => {
  const result = toUiKitProvider(provider);

  if (result.provider === 'other') {
    return <ProviderOther name={result.name} />;
  }

  return <UiKitProviderLogo {...props} provider={result.provider} />;
};
