import { InputGroup, Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField } from 'components/fields';
import { Form } from 'components/form';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { ChangeEvent, useState } from 'react';
import { useFeature } from 'utils/feature-flags';
import { graphql } from 'utils/graphql';

export const GenericSamlProvideIdpInformation: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep }) => {
  const [identityProvider, setIdentityProvider] = useState('');

  const handleIdPSubmit = (): void => {
    void graphql().RequestIdentityProvider({
      identityProvider,
    });

    onNextStep();
  };

  return (
    <Article>
      <Title className="mb-4">Step 1: Provide IdP Information</Title>

      <Card>
        <Form
          disabled={!identityProvider}
          isInline={true}
          onSubmit={handleIdPSubmit}
        >
          <InputGroup
            autoFocus={true}
            id="identity_provider"
            label="Which Identity Provider are you connecting to?"
            name="identity_provider"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setIdentityProvider(event.target.value)
            }
            placeholder="e.g. Okta, Azure AD, OneLogin..."
            value={identityProvider}
          />
        </Form>
      </Card>
    </Article>
  );
};

export const GenericSamlCreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: Create a Generic SAML Application</Title>

    <Text>
      Create a generic SAML application in your identity provider. You'll need
      the following Assertion Consumer Service (ACS) URL. The ACS URL is the
      location an Identity Provider redirects its authentication response to.
    </Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Confirm label="I've created a SAML application." onClick={onNextStep} />
  </Article>
);

export const GenericSamlProvideSpIdentity: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 3: Provide the SP Entity ID</Title>

    <Text>
      The Service Provider Entity ID is a unique value which represents{' '}
      {appName} within your Identity Provider. Provide the value below to your
      Identity Provider.
    </Text>

    <CopyInput
      label="Copy the SP Entity ID"
      value={connection?.saml_entity_id}
    />

    <Confirm
      label="I've provided the SP Entity ID to my IdP."
      onClick={onNextStep}
    />
  </Article>
);

export const GenericSamlUploadMetadata: React.FC<
  Readonly<ConnectionStepProps>
> = (connectionStepProps) => {
  const {
    connection,
    errors,
    isLoading,
    onFileInput,
    onInputChange,
    onNextStep,
    validationErrors,
  } = connectionStepProps;

  const isInputSamlCertificateMetadataUrlEnabled = useFeature(
    'inputSamlCertificateMetadataUrl',
  );

  if (!isInputSamlCertificateMetadataUrlEnabled) {
    return (
      <Article>
        <Title className="mb-4">
          Step 4: Upload Identity Provider Metadata
        </Title>

        <Text>
          Your IdP SSO Endpoint is the entry point for SAML Requests. Users will
          be redirected here to initiate single sign-on.
        </Text>

        <Text>
          Your X.509 certificate is issued by your Identity Provider and is used
          to verify incoming SAML Responses from the Service Provider.
        </Text>

        <Card>
          <Form
            disabled={
              !connection?.saml_idp_url ||
              !connection?.saml_x509_certs ||
              !connection?.saml_entity_id ||
              validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
            }
            isLoading={isLoading}
            isUpdate={
              !!(
                errors?.saml_idp_url ||
                errors?.saml_entity_id ||
                errors?.saml_x509_certs
              )
            }
            onSubmit={onNextStep}
          >
            <InputGroup
              autoFocus={true}
              id="saml_idp_url"
              label="IdP SSO Endpoint"
              name="saml_idp_url"
              onChange={onInputChange}
              placeholder="https://idp.com/login"
              value={connection?.saml_idp_url ?? undefined}
            />

            <FileField
              error={errors?.saml_x509_certs}
              label="X.509 Certificate"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />
          </Form>
        </Card>
      </Article>
    );
  }

  return (
    <MetadataUrlStep
      {...connectionStepProps}
      idpEntityIdPlaceholder="https://mysamlserver.com/abc123..."
      idpSsoUrlPlaceholder="https://mysamlserver.com/..."
      metadataUrlPlaceholder="https://"
      stepTitle="Step 4: Upload Identity Provider Metadata"
    >
      <Text>
        To connect with your SAML provider, please upload your identity
        provider's metadata.
      </Text>

      <Text as="h2" size="large" weight="medium">
        URL Metadata Configuration
      </Text>

      <Text>
        Upload the IdP metadata URL from your SAML settings. Some Generic SAML
        providers might not be able to provide the IdP Metadata URL. In these
        cases, you'll want to manually configure the connection.
      </Text>
    </MetadataUrlStep>
  );
};

export const GenericSamlConfigureClaims: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 5: Configure your SAML Claims</Title>

    <Text>
      The final step is to confirm that user attributes map to the following
      SAML claims.
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I've finished configuring the SAML claims."
      onClick={onNextStep}
    />
  </Article>
);
