import { Pill } from '@workos-inc/component-library';
import React, { Fragment } from 'react';

export type Attribute = {
  name: string;
  isCopyable?: boolean;
};

export type AttributeMappingProps = {
  labels?: string[];
  attributeGroups: Attribute[][];
};

export const AttributeMapping: React.FC<Readonly<AttributeMappingProps>> = ({
  labels,
  attributeGroups,
}) => (
  <div className="grid items-center gap-4 my-4 grid-cols-[1fr_min-content_1fr]">
    {labels &&
      labels.map((label: string, index) => (
        <Fragment key={label}>
          <label className="font-medium text-base flex-1 -mb-2">{label}</label>
          {index !== labels.length - 1 && <div />}
        </Fragment>
      ))}
    {attributeGroups.map((attributes: Attribute[], index) => (
      <Attributes key={index} attributes={attributes} />
    ))}
  </div>
);

type AttributesProps = {
  attributes?: Attribute[];
};

export const Attributes: React.FC<Readonly<AttributesProps>> = ({
  attributes = [],
}) => (
  <>
    {attributes.map(({ name, isCopyable = true }: Attribute, index: number) => (
      <Fragment key={name}>
        <Pill className="justify-between" isCopyable={isCopyable}>
          {name}
        </Pill>

        {index !== attributes.length - 1 && (
          <i className="my-0 mx-4 text-lg">→</i>
        )}
      </Fragment>
    ))}
  </>
);
