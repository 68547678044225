import { InputGroup, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { Form } from 'components/form';
import { CopyInput } from 'components/input';
import { Article, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { ChangeEvent, useState } from 'react';
import { graphql } from 'utils/graphql';

export const OpenIDProvideInformation: React.FC<
  Readonly<ConnectionStepProps>
> = ({ onNextStep }) => {
  const [identityProvider, setIdentityProvider] = useState('');

  const handleIdPSubmit = (): void => {
    void graphql().RequestIdentityProvider({
      identityProvider,
    });
    onNextStep();
  };

  return (
    <Article>
      <Title className="mb-4">Step 1: Provide IdP Information</Title>

      <Card>
        <Form
          disabled={!identityProvider}
          isInline={true}
          onSubmit={handleIdPSubmit}
        >
          <InputGroup
            autoFocus={true}
            id="identity_provider"
            label="Which Identity Provider are you connecting to?"
            name="identity_provider"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setIdentityProvider(event.target.value)
            }
            placeholder="e.g. Okta, Azure AD, OneLogin..."
            value={identityProvider}
          />
        </Form>
      </Card>
    </Article>
  );
};

export const OpenIDCreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 2: Create an Application with your IdP</Title>

    <Text>
      For SSO to properly function with your Identity Provider, you'll need to
      create and configure your application to:
    </Text>

    <ul className="my-4 list-disc list-inside space-y-3">
      <li>Support the authorization code grant type</li>
      <li>Have the URL below listed as a login redirect URI</li>
    </ul>

    <CopyInput
      label="Copy your login redirect URI"
      value={connection?.oidc_redirect_uri}
    />

    <Confirm
      label="I’ve created and configured my application"
      onClick={onNextStep}
    />
  </Article>
);

export const OpenIDProvideClientCredentials: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, errors, isLoading, onInputChange, onNextStep }) => (
  <Article>
    <Title className="mb-4">Step 3: Provide your Client Credentials</Title>

    <Text>
      After creating an application in the last step, a Client ID and Client
      Secret would've been provisioned for you by your Identity Provider. These
      credentials are used to authenticate users from {appName}.
    </Text>

    <Card>
      <Form
        disabled={
          !connection?.oidc_client_id || !connection?.oidc_client_secret
        }
        isLoading={isLoading}
        isUpdate={!!(errors?.oidc_client_id || errors?.oidc_client_secret)}
        onSubmit={onNextStep}
      >
        <InputGroup
          autoFocus={true}
          error={errors?.oidc_client_id?.message}
          id="oidc_client_id"
          label="Client ID"
          name="oidc_client_id"
          onChange={onInputChange}
          value={connection?.oidc_client_id ?? undefined}
        />

        <InputGroup
          error={errors?.oidc_client_secret?.message}
          id="oidc_client_secret"
          label="Client Secret"
          name="oidc_client_secret"
          onChange={onInputChange}
          value={connection?.oidc_client_secret ?? undefined}
        />
      </Form>
    </Card>
  </Article>
);

export const OpenIDProvideDiscoveryEndpoint: React.FC<
  Readonly<ConnectionStepProps>
> = ({
  connection,
  errors,
  isLoading,
  onInputChange,
  onNextStep,
  validationErrors,
}) => (
  <Article>
    <Title className="mb-4">Step 4: Provide your Discovery Endpoint</Title>

    <Text>
      Your Identity Provider's Discovery Endpoint contains important
      configuration information.
    </Text>

    <Card>
      <Form
        disabled={!connection?.oidc_discovery_endpoint}
        error={
          errors?.oidc_discovery_endpoint ||
          validationErrors?.oidc_discovery_endpoint
        }
        isInline={true}
        isLoading={isLoading}
        isUpdate={!!errors?.oidc_discovery_endpoint}
        onSubmit={onNextStep}
      >
        <InputGroup
          autoFocus={true}
          id="oidc_discovery_endpoint"
          label="Discovery Endpoint"
          name="oidc_discovery_endpoint"
          onChange={onInputChange}
          placeholder="https://your-domain.com/.well-known/openid-configuration"
          value={connection?.oidc_discovery_endpoint ?? undefined}
        />
      </Form>
    </Card>
  </Article>
);
